import React, { useEffect } from "react";
import "./App.css";
import {
  AppShell,
  Field,
  Form,
  FormLayout,
  LoadingSpinner,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Select,
  SelectButton,
  SelectList,
  useSnackbar,
} from "@saas-ui/react";

import { Button, Box, Container } from "@chakra-ui/react";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const BASE_URL = "https://certifiedcode.wixsite.com/ecwid-protect";

  const snackbar = useSnackbar();
  const [isEnabled, setEnabled] = React.useState(false);
  const [instance, setInstance] = React.useState({} as any);
  const [config, setConfig] = React.useState({
    zIndex: 99999, // z-index css property of popup, increase if popup hides behind other elements
    popupBorderRadius: 24, // border-radius of the popup(rounded corners)
    popupMaxWidth: 500, // Max width of the popup
    secureMode: true, // true|false - If true, right-click and element inspection will be disabled
    showCloseIcon: true, // Show close icon to hide popup
    popupBackgroundColor: "#ffffff", // The background color of popup
    popupBackdropColor: "rgba(0, 0, 0, 0.6)", // Backdrop or overlay color behind popup
    iconColor: "#E2535C", // Icon color of popup (Please note there are 3 icons already present in the file (handIcon, adIcon, faceIcon) you can use any of the icons, just change icon name in constructModal function)
    btnBackgroundColor: "#E2535C", // Button(I've disabled AdBlock) background color
    btnColor: "#ffffff", // Button(I've disabled AdBlock) font color
    titleColor: "#000000", // Title color of popup
    descColor: "#000000", // Description color of popup
    helpTextColor: "#000000", // Help Text(How do I disable my adblocker?/Okay, I understand!) color of popup
    screenTwoStepsColor: "#000000", // Screen two ad block disable steps color
    titleText: "AdBlock Detected!", // Popup title Text
    descText:
      "It looks like you have an adblocker enabled. In order to support our website and continue accessing our content, please disable your ad blocker for this site.", // Popup description text
    btnText: "I've disabled AdBlock", // Button(I've disabled AdBlock) text
    screenOneHelpText: "How do I disable my adblocker?", // Screen one toggle helper text
    screenTwoHelpText: "Okay, I understand!", // Screen toggle toggle helper text
    screenTwoTitle: "To disable adblocker on this site:", // Screen two title text
    screenTwoSteps: [
      // Screen two adblock disable steps text
      "Click on the adblocker extension icon located at the top right corner of your browser",
      'From the menu choose "Disable on this site" or "Pause on this site"',
      "Refresh the page if not automatically refreshed",
    ],
  } as any);

  useEffect(() => {
    try {
      // Initialize the application
      window.EcwidApp.init({
        app_id: "adsblocker", // client_id of your application
        autoloadedflag: true,
        autoheight: false,
      });

      getInstance();
      getSettings();

      window.EcwidApp.setSize({ height: 800 });

      const checker = setInterval(() => {
        getInstance();
      }, 10000);

      return () => {
        clearInterval(checker);
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    console.log(instance);
    if (instance.intercomToken) {
      const name = instance.storeProfile.account.accountName
      const registrationDate = new Date(instance.storeProfile.account.registrationDate)
      const created_at = Math.floor(registrationDate.getTime() / 1000)
      const phone = instance.storeProfile.company.phone
      const email = instance.storeProfile.account.accountEmail
      const utm_source = instance.app.client_id
      const options = {
        email,
        name,
        created_at,
        phone,
        user_hash: instance.intercomToken,
        utm_source,
      }
      window.Intercom("boot", options);
    }
  }, [instance]);

  function setSettings(isEnabled?: boolean) {
    return fetch(
      BASE_URL +
        "/_functions/@certifiedcode/ecwid-repluggable-backend/settings",
      {
        method: "POST",
        headers: {
          Authorization: new URLSearchParams(window.location.search).get(
            "payload"
          )!,
        },
        body: JSON.stringify({
          isEnabled,
          config,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setConfig(data.config);
        setEnabled(data.isEnabled);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getInstance() {
    fetch(
      BASE_URL + "/_functions/@certifiedcode/ecwid-repluggable-backend/auth",
      {
        headers: {
          Authorization: new URLSearchParams(window.location.search).get(
            "payload"
          )!,
        },
      }
    )
      .then((response) => response.json())
      .then((payload) => {
        setInstance(payload);
        window.EcwidApp.ready();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getSettings() {
    fetch(
      BASE_URL +
        "/_functions/@certifiedcode/ecwid-repluggable-backend/settings",
      {
        method: "GET",
        headers: {
          Authorization: new URLSearchParams(window.location.search).get(
            "payload"
          )!,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setEnabled(data.isEnabled);
        if (data.config) {
          setConfig(data.config);
        }
      })
      .catch(() => {});
  }

  return (
    <AppShell
      navbar={
        <Navbar>
          <NavbarBrand>Protect: Ads Blocker Detector</NavbarBrand>
          <NavbarContent justifyContent="flex-end" spacing="2">
            <NavbarItem>{/* <Button href="#">Login</Button> */}</NavbarItem>
            <NavbarItem>
              {instance.store_id ? (
                !instance?.subscription ? (
                  <Button
                    variant="primary"
                    as="a"
                    href={
                      instance?.storeProfile
                        ? encodeURI(
                            "https://www.certifiedcode.us/upgrade/apps?checkout=" +
                              JSON.stringify({
                                settings: {
                                  allowLogout: false,
                                  allowDiscountRemoval: false,
                                  // successUrl:
                                  // // check if it is local host:
                                  // window.location.href.includes("localhost") ? null :
                                  // window.location.href,
                                  // variant: "one-page"
                                },
                                items: [
                                  {
                                    priceId: "pri_01ja5mkg0dvpapjnsg4xr85k67",
                                    quantity: 1,
                                  },
                                ],
                                customer: {
                                  email:
                                    instance.storeProfile.account.accountEmail,
                                  business: {
                                    name: instance.storeProfile.account
                                      .accountName,
                                  },
                                  address: {
                                    countryCode:
                                      instance.storeProfile.company.countryCode,
                                    postalCode:
                                      instance.storeProfile.company.postalCode,
                                    city: instance.storeProfile.company.city,
                                  },
                                },
                                customData: {
                                  store_id: `${instance.store_id}`,
                                },
                              })
                          )
                        : undefined
                    }
                    target="_blank"
                  >
                    Start a trial
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    as="a"
                    href={
                      instance.subscription.management_urls
                        .update_payment_method
                    }
                    target="_blank"
                  >
                    Manage Billing Method
                  </Button>
                )
              ) : (
                <LoadingSpinner />
              )}
            </NavbarItem>
          </NavbarContent>
        </Navbar>
      }
    >
      <Box as="main" flex="1" py="2" px="4">
        <Container
          maxW="container.xl"
          pt="8"
          px="8"
          display="flex"
          flexDirection="column"
          margin="0 auto"
        >
          <Form onSubmit={() => {}}>
            <FormLayout>
              <Select
                name={"Enable?"}
                options={[
                  { label: "Enable", value: "true" },
                  { label: "Disable", value: "false" },
                ]}
                onChange={(value) => {
                  setEnabled(value === "true");
                }}
                value={isEnabled ? "true" : "false"}
              >
                <SelectButton />
                <SelectList />
              </Select>
              <Field
                name="title"
                label="Title"
                value={config.titleText}
                onChange={(e: any) => {
                  setConfig({ ...config, titleText: e.target.value });
                }}
              />
              <Field
                name="description"
                label="Description"
                value={config.descText}
                onChange={(e: any) => {
                  setConfig({ ...config, descText: e.target.value });
                }}
              />
              <Field
                name="buttonLabel"
                label="Button Label"
                value={config.btnText}
                onChange={(e: any) => {
                  setConfig({ ...config, btnText: e.target.value });
                }}
              />

              <Button
                disabled={instance?.subscription ? false : true}
                onClick={() =>
                  snackbar.promise(setSettings(isEnabled), {
                    loading: "Checking your website ...",
                    success: "The website is protected!",
                    error: "Something went wrong, please try again.",
                  })
                }
              >
                {instance?.subscription
                  ? isEnabled
                    ? "Save Changes & Enable"
                    : "Save Changes & Disable"
                  : "Start a trial to enable"}
              </Button>
            </FormLayout>
          </Form>
        </Container>
      </Box>
    </AppShell>
  );
}

export default App;
